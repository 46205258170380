<div class="page-container scheme-container">
  <p-toast />
  <div class="header-container">
    <app-search-text (searchEvent)="handleSearch($event)" class="header" />
    <app-button label="Add" (clickEvent)="handleAddNew()" class="header" />
  </div>

  <p-table
    [columns]="cols"
    [value]="SchemeList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td class="scheme-name" (click)="nagivateToActivities(rowData)">
          {{ rowData.name }}
        </td>
        <td>{{ rowData.regulator }}</td>
        <td>
          <img src="assets/locationIcon.svg" style="margin-right: 5px" />
          {{ rowData.states }}
        </td>
        <td (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-overlayPanel #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div>
              <div
                (mouseover)="handleHover(2)"
                (click)="handleDelete(rowData)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="SchemeList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="addDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '45vw' }"
  >
    <ng-template pTemplate="header">
      <h3>{{ editForm ? "Edit Scheme" : "Add Scheme" }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div class="grid-container">
          <app-input
            label="Name"
            [control]="formData.get('name')"
            [required]="true"
          />
          <app-dropdown
            [options]="RegulatorList"
            [control]="formData.get('regulator')"
            label="Regulator"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
            [required]="true"
          />
          <div>
            <label class="input-label">
              Available States
              <span class="required-label">*</span>
            </label>
            <p-multiSelect
              [options]="StateList"
              formControlName="states"
              optionLabel="label"
              placeholder="Select"
              appendTo="body"
              optionValue="label"
            />
            <div
              class="error-message"
              *ngIf="
                formData.get('states')?.invalid &&
                formData.get('states')?.touched
              "
            >
              States is required.
            </div>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        *ngIf="!editForm"
        label="Save"
        [disabled]="formData.invalid"
        (clickEvent)="onSave()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
      <app-button
        *ngIf="editForm"
        label="Update"
        [disabled]="formData.invalid"
        (clickEvent)="onUpdate()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="deleteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Scheme ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Delete"
        (clickEvent)="onDeleteScheme()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>
