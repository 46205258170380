import ROUTES from './routes.const';

export const SideBarList = [
  {
    label: 'Dashboard',
    key: 'dashboard',
    link: ROUTES.DASHBOARD,
    icon: './assets/SidebarIcon/dashboard.svg',
  },
  {
    label: 'Aggregator',
    key: 'aggregator',
    link: ROUTES.AGGREGATOR,
    icon: './assets/SidebarIcon/Aggregator.svg',
  },
  {
    label: 'Installers',
    key: 'installers',
    link: ROUTES.INSTALLERS,
    icon: './assets/SidebarIcon/Installers.svg',
  },
  {
    label: 'Catalogue',
    key: 'catalogue',
    link: ROUTES.CATALOGUE,
    icon: './assets/SidebarIcon/Catalogue.svg',
  },
  {
    label: 'Scheme',
    key: 'scheme',
    link: ROUTES.SCHEME,
    icon: './assets/SidebarIcon/Scheme.svg',
    subMenu: [
      { label: 'Manage Zone', key: 'manage-zone', link: ROUTES.MANAGE_ZONE },
      { label: 'Manage Field', key: 'manage-field', link: ROUTES.MANAGE_FIELD },
      {
        label: 'Hand-Out Documents',
        key: 'hand-out-documents',
        link: ROUTES.HAND_OUT_DOCUMENTS,
      },
      { label: 'Forms', key: 'forms', link: ROUTES.FORMS },
      { label: 'Simulate Job', key: 'simulate-job', link: ROUTES.SIMULATE_JOB },
      // {
      //   label: 'Project Fields',
      //   key: 'project-fields',
      //   link: ROUTES.PROJECT_VARIABLES,
      // },
    ],
  },
  {
    label: 'Certificate Value',
    key: 'certificate-value',
    link: ROUTES.CERTIFICATE_VALUE,
    icon: './assets/SidebarIcon/CertificateValue.svg',
  },
  {
    label: 'Task Management',
    key: 'task-management',
    link: ROUTES.TASK_MANAGEMENT,
    icon: './assets/SidebarIcon/task.icon.svg',
  },
];

export const MANAGE_ZONE_COLS = [
  { field: 'zone_name', header: 'Zone' },
  { field: 'state', header: 'State' },
  { field: 'postal_code', header: 'Postal Code' },
  { field: 'from_date', header: 'From Date' },
  { field: '', header: '' },
];

export const AGGREGATOR_COLS = [
  { field: '', header: '' },
  { field: 'aggregator', header: 'Aggregator' },
  { field: 'ABN', header: 'ABN' },
  { field: 'status', header: 'Status' },
  { field: '', header: '' },
];

export const STATE_LIST = [
  { label: 'ACT', value: 'ACT' },
  { label: 'NSW', value: 'NSW' },
  { label: 'NT', value: 'NT' },
  { label: 'QLD', value: 'QLD' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' },
];

export const HAND_OUT_DOCUMENTS_COLS = [
  { field: 'title', header: 'Title' },
  { field: 'version', header: 'Version' },
  { field: 'document', header: 'Document' },
  { field: '', header: '' },
];

export const ACTIVITY_DOCUMENTS_COLS = [
  { field: 'title', header: 'Title' },
  { field: 'version', header: 'Version' },
  { field: 'document', header: 'Document' },
];

export const FORMS_COLS = [
  { field: 'name', header: 'Name' },
  { field: 'type', header: 'Type' },
  { field: 'Regulator', header: 'Regulator' },
  { field: 'stage', header: 'Stage' },
  { field: 'License Type', header: 'License Type' },
  { field: 'State', header: 'State' },
  { field: '', header: '' },
];

export const MANAGE_FIELD_COLS = [
  { field: 'code', header: 'Code' },
  { field: 'name', header: 'Label' },
  { field: 'type', header: 'Type' },
  { field: 'description', header: 'Description' },
  { field: 'stage', header: 'Stage' },
  { field: 'adlternative_fields', header: 'Alternative Fields' },
  { field: '', header: '' },
];

export const TYPE_LIST = [
  { label: 'Constant', value: 'Constant' },
  { label: 'Photo', value: 'Photo' },
  { label: 'DE-Placeholder', value: 'DE_Placeholder' },
  { label: 'DE-Picklist', value: 'DE_Picklist' },
  { label: 'DE-Numeric', value: 'DE_Numeric' },
  { label: 'Boolean', value: 'Boolean' },
  { label: 'Signature', value: 'Signature' },
  { label: 'DE-Unique', value: 'DE_Unique' },
];

export const FIELD_STAGE = [
  { label: 'FormField', value: 'FormField' },
  { label: 'ProjectField', value: 'ProjectField' },
  { label: 'InstallField', value: 'InstallField' },
  { label: 'AssessmentField', value: 'AssessmentField' },
];

export const MANAGE_FIELD_CONSTANT = [
  { field: 'zone', header: 'Zone' },
  { field: 'from_date', header: 'From Date' },
  { field: 'values', header: 'Values' },
  { field: '', header: '' },
];

export const MANAGE_FIELD_PICKLIST = [
  { field: 'zone', header: 'Zone' },
  { field: 'from_date', header: 'From Date' },
  { field: 'key', header: 'Key' },
  { field: 'values', header: 'Values' },
  { field: '', header: '' },
];

export const SIMULATE_COLS = [
  { field: 'Scheme', header: 'Scheme' },
  { field: 'Activities', header: 'Activities' },
];

export const DEVICE_TYPE = [
  { label: 'HWHEATPUMP', value: 'HWHEATPUMP' },
  { label: 'LED', value: 'LED' },
  { label: 'SOLAR', value: 'SOLAR' },
];

export const DWELLING_TYPE = [
  { label: 'Residential', value: 'Residential' },
  { label: 'Commercial', value: 'Commercial' },
];

export const LICENCE_LIST = [
  { label: 'ELECTRICIAN', value: 'ELECTRICIAN' },
  { label: 'PLUMBER', value: 'PLUMBER' },
  { label: 'SOLAR', value: 'SOLAR' },
  { label: 'AIRCON', value: 'AIRCON' },
];

export const SCHEME_COLS = [
  { field: 'name', header: 'Name' },
  { field: 'regulator', header: 'Regulator' },
  { field: 'state', header: 'State' },
  { field: '', header: '' },
];

export const ACTIVITES_COLS = [
  { field: 'name', header: 'Name' },
  { field: 'device_type', header: 'Device Type' },
  { field: 'dwelling_type', header: 'Dwelling Type' },
  { field: '', header: '' },
];

export const REGULATOR_LIST = [
  { label: 'IPART', value: 'IPART' },
  { label: 'ESC', value: 'ESC' },
  { label: 'CER', value: 'CER' },
];

export const FORM_TYPE_LIST = [
  { label: 'QUOTE', value: 'QUOTE' },
  { label: 'NOMINATION', value: 'NOMINATION' },
  { label: 'INVOICE', value: 'INVOICE' },
  { label: 'COMPLIANCE', value: 'COMPLIANCE' },
  { label: 'AUDIT', value: 'AUDIT' },
  { label: 'SUBMISSION', value: 'SUBMISSION' },
];

export const FORM_STAGE_LIST = [
  { label: 'QUOTE', value: 'QUOTE' },
  { label: 'INVOICE', value: 'INVOICE' },
  { label: 'AUDIT', value: 'AUDIT' },
];

export const ACTIVITES_EDIT = [
  { field: 'field', header: 'Field' },
  { field: 'filed_type', header: 'Filed Type' },
];

export const USER_COLS = [
  { field: 'name', header: 'User' },
  { field: 'email', header: 'E-mail' },
  { field: 'status', header: 'Status' },
  { field: '', header: '' },
];

export const DASHBOARD_TABLE = [
  { field: 'name', header: 'Name' },
  { field: 'New', header: 'New' },
  { field: 'Assessments', header: 'Assessments' },
  { field: 'Installation', header: 'Installation' },
  { field: 'Audit', header: 'Audit' },
  { field: 'Complete', header: 'Complete' },
];

export const CATALOGUE_COLS = [
  { field: 'model', header: 'Model' },
  { field: 'manufacture', header: 'Manufacture' },
  { field: 'category', header: 'Category' },
  { field: '', header: '' },
];

export const MONTHLY = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const WEEKLY = ['Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

export const DAILY = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export const ACTIVITIES = [
  { field: 'name', header: 'Name' },
  { field: 'code', header: 'Code' },
  { field: 'code', header: 'Registration Code' },
  { field: 'device_type', header: 'Device Type' },
  { field: 'dwelling_type', header: 'Dwelling Type' },
  { field: 'co-payment', header: 'Minimum Co-Payment' },
  { field: '', header: '' },
];

export const FIELD_SET_COLS = [
  { field: 'assessment', header: 'Assessment' },
  { field: 'installation', header: 'Installation' },
  { field: 'valid_from', header: 'Valid From' },
  { field: '', header: '' },
];

export const INSTALLER_COLS = [
  { field: 'installer', header: 'Installer' },
  { field: 'email', header: 'Email' },
  { field: 'status', header: 'Status' },
  { field: '', header: '' },
];

export const CERTIFICATE_VALUE = [
  { label: 'ACCU', value: 'ACCU' },
  { label: 'ESC', value: 'ESC' },
  { label: 'LGC', value: 'LGC' },
  { label: 'PRC', value: 'PRC' },
  { label: 'STC', value: 'STC' },
  { label: 'VEEC', value: 'VEEC' },
];

export const TASK_COLS = [
  { field: 'TaskName', header: 'Task Name' },
  { field: 'deviceType', header: 'Device Type' },
  { field: '', header: 'Replace / New' },
  { field: '', header: '' },
];

export const CATEGORIES = [
  { label: 'Replace', value: 'replace' },
  { label: 'New', value: 'new' },
];

export const TOKEN = 'admin_token';

export const ABN_URL =
  'https://abr.business.gov.au/json/AbnDetails.aspx?callback=JSONP_CALLBACK&guid=f5403340-360c-483d-ae43-ea38cb140ca0&noCacheIE=1680516399638';

export const PROJECT_STATUS = [
  { label: 'NEW', value: 'NEW' },
  { label: 'ASSESSMENT', value: 'ASSESSMENT' },
  { label: 'QUOTE', value: 'QUOTE' },
  { label: 'INSTALLATION', value: 'INSTALLATION' },
  { label: 'INVOICE', value: 'INVOICE' },
  { label: 'COMPLETED', value: 'COMPLETED' },
  { label: 'PAID', value: 'PAID' },
];
