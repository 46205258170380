import { Component } from '@angular/core';
import { SearchFieldComponent } from '../../../Components/searchField/search-text.component';
import { ButtonComponent } from '../../../Components/button/button.component';
import { SharedModule } from '../../../shared.module';
import {
  DropDownType,
  ManageFieldData,
  ManageZoneData,
  TableCols,
} from '../../../types';
import {
  FIELD_STAGE,
  LICENCE_LIST,
  MANAGE_FIELD_COLS,
  MANAGE_FIELD_CONSTANT,
  TYPE_LIST,
} from '../../../../constants';
import { InputComponent } from '../../../Components/input/input.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { CalendarComponent } from '../../../Components/calendar/calendar.component';
import { NumberInputComponent } from '../../../Components/number-input/number-input.component';
import { PicklistTableComponent } from './picklist/picklist.component';
import { FieldService } from './field.services';
import { MessageService } from 'primeng/api';
import { DataService } from '../manage-zone/api.services';
import moment from 'moment';
import { ProjectFieldsService } from '../project-variables/project-variables.services';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-manage-fields',
  standalone: true,
  templateUrl: './manage-fields.component.html',
  styleUrl: './manage-fields.component.scss',
  providers: [MessageService],
  imports: [
    SearchFieldComponent,
    ButtonComponent,
    SharedModule,
    InputComponent,
    DropdownComponent,
    CalendarComponent,
    NumberInputComponent,
    PicklistTableComponent,
    CheckboxModule,
  ],
})
export class ManageFieldsComponent {
  cols: TableCols[] = MANAGE_FIELD_COLS;
  ManageFieldList: ManageFieldData[] = [];
  onHover: number = 0;
  display: boolean = false;
  edit: boolean = false;
  formData!: FormGroup;
  typeList: DropDownType[] = TYPE_LIST;
  licenseType: DropDownType[] = LICENCE_LIST;
  fieldStage: DropDownType[] = FIELD_STAGE;
  autoComplete: ManageFieldData[] = [];
  constantValueForm!: FormGroup;
  ConstantCols: TableCols[] = MANAGE_FIELD_CONSTANT;
  zoneList: ManageZoneData[] = [];
  constantList: any[] = [];
  editConstant: boolean = false;
  constRowId: number = 0;
  picklistData: any[] = [];
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  deleteDialog: boolean = false;
  rowId: number = 0;
  rowData!: ManageFieldData;
  onSaveLoad: boolean = false;
  fieldDataClone: ManageFieldData[] = [];
  selectedRows: ManageFieldData[] = [];
  projectForm!: FormGroup;
  projectFieldDialog: boolean = false;
  stages: any[] = [
    { label: 'PreInstall', value: 0 },
    { label: 'PreQuote', value: 1 },
  ];
  searchText: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private service: FieldService,
    private zoneService: DataService,
    private messageService: MessageService,
    private projectService: ProjectFieldsService
  ) {
    this.initialFormData();
    this.initialConstantForm();
    this.initialprojectForm();
  }

  async ngOnInit() {
    this.ManageFieldList = await this.fieldData();
    let fetchedZoneList = await this.zoneService.fetchZoneData();

    const defaultZone = {
      id: null,
      name: 'All Zone',
      state: '',
      createdAt: '',
      updatedAt: '',
      zoneValues: [],
    };
    fetchedZoneList.unshift(defaultZone);

    this.zoneList = fetchedZoneList;
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  onProjectTypeChange(type: any): void {
    this.projectForm.patchValue({ type });
  }

  private async fieldData(): Promise<ManageFieldData[]> {
    try {
      const data = await this.service.fetchFieldData();
      if (data) this.isLoading = false;
      const fields = data.map((field: ManageFieldData) => ({
        ...field,
        alternateFieldCodes: field.alternateFieldCodes.split(','),
      }));
      this.fieldDataClone = this.sortByCode(fields);
      return this.sortByCode(fields);
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
      return [];
    }
  }

  private sortByCode(zones: ManageFieldData[]): ManageFieldData[] {
    return zones.slice().sort((a, b) => a.code.localeCompare(b.code));
  }

  formatFieldType(fieldType: string): string {
    return fieldType.replace(/_/g, '-');
  }

  private initialprojectForm(): void {
    this.projectForm = this.formBuilder.group({
      type: [this.stages[0]],
    });
  }

  private initialFormData(): void {
    this.formData = this.formBuilder.group({
      label: ['', Validators.required],
      fieldType: ['', Validators.required],
      code: ['', Validators.required],
      alternateFieldCodes: [[]],
      fieldStage: [''],
      licenseType: [null],
      description: ['', Validators.required],
      isMandatory: new FormControl<boolean | null>(false),
      isScanner: new FormControl<boolean | null>(false),
      isAudit: new FormControl<boolean | null>(false),
    });
  }

  private initialConstantForm(): void {
    this.constantValueForm = this.formBuilder.group({
      zone: [''],
      from_date: [''],
      values: [0],
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async handleAddNew(): Promise<void> {
    this.display = true;
    this.initialFormData();
    this.initialConstantForm();
    this.edit = false;
    this.constantList = [];
    this.picklistData = [];
  }

  autoCompleteFunc(event: AutoCompleteCompleteEvent) {
    const query = event.query.toLowerCase();
    this.autoComplete = this.ManageFieldList.filter((item) =>
      item.label.toLowerCase().startsWith(query)
    );
  }

  handleEdit(rowData: ManageFieldData): void {
    this.edit = true;
    this.display = true;
    this.rowData = rowData;

    const data = rowData.alternateFieldCodes;
    const alterFields = this.ManageFieldList.filter((item) => {
      return data.includes(item.label);
    });

    this.formData.patchValue({
      label: rowData.label,
      fieldType: rowData.fieldType,
      code: rowData.code,
      alternateFieldCodes: alterFields,
      description: rowData.description,
      fieldStage: rowData.fieldStage,
      licenseType: rowData.licenseType,
      isMandatory: rowData.isMandatory,
      isScanner: rowData.isScanner,
      isAudit: rowData.isAudit,
    });

    if (rowData.fieldType === 'Constant') {
      const constantData = rowData.fieldValues.map((c: any) => ({
        zone: this.zoneList.find((zone) => zone.id === c.zoneId),
        from_date: c.dateFrom,
        values: c.values,
      }));
      this.constantList = constantData || [];
    }

    if (rowData.fieldType === 'DE_Picklist') {
      const picklist = rowData.fieldValues.map((c) => ({
        date: c.dateFrom,
        zone: this.zoneList.find((zone) => zone.id === c.zoneId),
        values: JSON.parse(c.values),
      }));
      this.picklistData = picklist || [];
    }
  }

  closeDialog(): void {
    this.display = false;
    this.edit = false;
    this.deleteDialog = false;
    this.projectFieldDialog = false;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const trimmedFormData = this.trimFormData(this.formData.value);
      const { alternateFieldCodes, fieldValues } = this.formatFieldData();

      const payload = {
        ...trimmedFormData,
        alternateFieldCodes,
        fieldValues,
      };

      const res = await this.service.createFieldData(payload);
      if (res) {
        this.ManageFieldList = await this.fieldData();
        this.display = false;
        this.onSaveLoad = false;
        this.successToast('Field Created Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const trimmedFormData = this.trimFormData(this.formData.value);
      const { alternateFieldCodes, fieldValues } = this.formatFieldData();

      const payload = {
        ...this.rowData,
        ...trimmedFormData,
        alternateFieldCodes,
        fieldValues,
      };

      const res = await this.service.editFieldData(payload);
      if (res) {
        this.ManageFieldList = await this.fieldData();
        this.handleSearch(this.searchText);
        this.display = false;
        this.onSaveLoad = false;
        this.successToast('Field Updated Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  private trimFormData(formData: any): any {
    const trimmedData: any = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        trimmedData[key] = typeof value === 'string' ? value.trim() : value;
      }
    }
    return trimmedData;
  }

  private formatFieldData(): {
    fieldType: string;
    alternateFieldCodes: string;
    fieldValues: any[];
  } {
    const fieldType = this.formData.get('fieldType')?.value || '';
    const alternateFieldCodes = this.getAlternateFieldCodes();
    const fieldValues = this.getFieldValues(fieldType);

    const formattedFieldValues = fieldValues.map((c) => ({
      zoneId: c.zone?.id,
      dateFrom: moment(c.from_date || c.date).format('YYYY-MM-DD'),
      values: fieldType === 'Constant' ? c.values : JSON.stringify(c.values),
    }));

    return {
      fieldType,
      alternateFieldCodes,
      fieldValues: formattedFieldValues,
    };
  }

  private getAlternateFieldCodes(): string {
    const alternateField = this.formData
      .get('alternateFieldCodes')
      ?.value.map((e: any) => e.label);
    return alternateField ? alternateField.join(',') : '';
  }

  private getFieldValues(fieldType: string): any[] {
    let fieldValues: any[] = [];
    if (fieldType === 'Constant') {
      fieldValues = this.constantList || [];
    } else if (fieldType === 'DE_Picklist') {
      fieldValues = this.picklistData || [];
    } else {
      fieldValues = [];
    }
    return fieldValues;
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.searchText = searchVal;
    this.ManageFieldList = this.fieldDataClone.filter(
      (item) =>
        item.label.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.fieldType.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.code.toLowerCase().includes(searchVal.toLowerCase()) ||
        item?.fieldStage?.toLowerCase().includes(searchVal.toLowerCase())
    );
  }

  constantValueAdd(): void {
    this.constantList.push(this.constantValueForm.value);
    this.constantValueForm.reset();
  }

  constantValueEdit(): void {
    this.constantList[this.constRowId] = this.constantValueForm.value;
    this.editConstant = false;
    this.constantValueForm.reset();
  }

  constantEdit(rowData: any, index: number): void {
    this.editConstant = true;
    this.constRowId = index;
    this.constantValueForm.patchValue({
      zone: rowData.zone,
      from_date: rowData.from_date,
      values: rowData.values,
    });
  }

  constantDelete(index: number): void {
    this.constantList.splice(index, 1);
  }

  getPicklistValue(data: any[]): void {
    this.picklistData = data;
  }

  async handleDelete(rowData: ManageFieldData): Promise<void> {
    this.deleteDialog = true;
    this.rowId = rowData.id;
  }

  async onDeleteField(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const res = await this.service.deleteFieldData(this.rowId);
      if (res) {
        this.ManageFieldList = await this.fieldData();
        this.handleSearch(this.searchText);
        this.deleteDialog = false;
        this.onSaveLoad = false;
        this.successToast('Field Delete Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  // async onMoveToProject(): Promise<void> {
  //   this.projectFieldDialog = true;
  // }
}
