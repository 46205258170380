import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class AggregatorService {
  constructor(private api: ApiService) {}

  async fetchAggregatorData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get(`api/aggregator`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createAggregatorData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post('api/aggregator', payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateAggregatorData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put('api/aggregator', payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteAggregatorData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.delete(`api/aggregator/${id}`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchAggregatorUser(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/aggregator/${id}/user`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async inviteAggregatorUser(id: number, payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/aggregator/${id}/user`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteAggregatorUser(aggregatorId: number, id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.delete(`api/aggregator/${aggregatorId}/user/${id}`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createAggregatorLogo(payload: any): Promise<any> {
    try {
      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      const data = await firstValueFrom(
        this.api.post(`api/aggregator/logo`, formData)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchAggregatorLogo(id: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/aggregator/${id}/logo`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async allSchemeActivitiesList(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/scheme/schemes-with-activities`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
