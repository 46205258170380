import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../../Services/common-services.service';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class DocumentsService {
  constructor(private api: ApiService) {}

  async fetchDocumentsData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get('api/hand-out-document'));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createDocumentsData(payload: any): Promise<any> {
    try {
      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      const data = await firstValueFrom(
        this.api.post('api/hand-out-document', formData)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async editDocumentsData(payload: any): Promise<any> {
    try {
      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      const data = await firstValueFrom(
        this.api.put('api/hand-out-document', formData)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteDocumentsData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.delete(`api/hand-out-document/${id}`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDocumentFile(id: number, title: string): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.getBlob(`api/hand-out-document/${id}/file`)
      );
      const blob = new Blob([data], { type: 'application/pdf' });
      const filename = `${title}.pdf`;
      saveAs(blob, filename);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createActivityDocuments(id: number, payload: any[]): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/scheme/activity/${id}/HandOutDocuments`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchActivityDocuments(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/scheme/activity/${id}/HandOutDocuments`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
