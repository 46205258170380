import { Component } from '@angular/core';
import { ButtonComponent } from '../../../Components/button/button.component';
import { InputComponent } from '../../../Components/input/input.component';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { CalendarComponent } from '../../../Components/calendar/calendar.component';
import { MANAGE_ZONE_COLS, STATE_LIST } from '../../../../constants';
import { SharedModule } from '../../../shared.module';
import { SearchFieldComponent } from '../../../Components/searchField/search-text.component';
import {
  FormatZoneValue,
  ManageZoneData,
  PostCode,
  TableCols,
  ZoneValue,
} from '../../../types';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DataService } from './api.services';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { NumberInputComponent } from '../../../Components/number-input/number-input.component';
import { postalCodeRangeValidator } from '../../common-methods';

@Component({
  selector: 'app-manage-zone',
  standalone: true,
  templateUrl: './manage-zone.component.html',
  styleUrl: './manage-zone.component.scss',
  providers: [FormBuilder, MessageService],
  imports: [
    SearchFieldComponent,
    ButtonComponent,
    InputComponent,
    DropdownComponent,
    CalendarComponent,
    SharedModule,
    ReactiveFormsModule,
    NumberInputComponent,
  ],
})
export class ManageZoneComponent {
  ManageZoneList: ManageZoneData[] = [];
  onHover: number = 0;
  display: boolean = false;
  editZone: boolean = false;
  stateList = STATE_LIST;
  cols: TableCols[] = MANAGE_ZONE_COLS;
  formData: FormGroup;
  postalCode: { from: string; to: string }[] = [{ from: '', to: '' }];
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  rowData!: ManageZoneData;
  deleteDialog: boolean = false;
  zoneValue!: ZoneValue;
  onSaveLoad: boolean = false;
  searchText: string = '';
  zoneDataClone: ManageZoneData[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private messageService: MessageService
  ) {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      state: [''],
      date: ['', Validators.required],
      postalCode: this.formBuilder.array(
        [this.createPostalCodeGroup()],
        postalCodeRangeValidator()
      ),
    });
  }

  get postalCodeControls() {
    return (this.formData.get('postalCode') as FormArray).controls;
  }

  createPostalCodeGroup() {
    return this.formBuilder.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.ManageZoneList = await this.zoneData();
  }

  private async zoneData(): Promise<ManageZoneData[]> {
    try {
      const data = await this.dataService.fetchZoneData();

      if (data) this.isLoading = false;
      const zones = data.map((zone: ManageZoneData) => ({
        ...zone,
        zoneValues: zone.zoneValues.map((zoneValue: ZoneValue) => ({
          ...zoneValue,
          postCodes: JSON.parse(zoneValue.postCodes),
        })),
      }));

      this.zoneDataClone = this.sortZonesByName(zones);
      return this.sortZonesByName(zones);
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
      return [];
    }
  }

  private sortZonesByName(zones: ManageZoneData[]): ManageZoneData[] {
    return zones.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  handleEdit(postalCode: FormatZoneValue, rowData: ManageZoneData): void {
    this.display = true;
    this.editZone = true;
    this.rowData = rowData;

    this.formData.patchValue({
      date: new Date(postalCode.dateFrom),
    });

    const postalCodes = this.formData.get('postalCode') as FormArray;
    postalCodes.clear();

    postalCode.postCodes.map((code: PostCode) =>
      postalCodes.push(
        this.formBuilder.group({
          from: code.from,
          to: code.to,
        })
      )
    );
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.searchText = searchVal;
    this.ManageZoneList = this.zoneDataClone.filter((item) =>
      item.name.toLowerCase().includes(searchVal.toLowerCase())
    );
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const { name, state, date, postalCode } = this.formData.value;
      const payload = {
        name: name.trim(),
        state: state ? state.trim() : null,
        zoneValues: [
          {
            dateFrom: moment(date).format('YYYY-MM-DD'),
            postCodes: JSON.stringify(postalCode),
          },
        ],
      };
      const response = await this.dataService.createZoneData(payload);
      if (response) {
        this.display = false;
        this.onSaveLoad = false;
        this.ManageZoneList = await this.zoneData();
        this.successToast('Zone Created Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  handleAddDialog(): void {
    this.display = true;
    this.editZone = false;
    this.formData.reset();
    const postalCodes = this.formData.get('postalCode') as FormArray;
    postalCodes.clear();
    postalCodes.push(
      this.formBuilder.group({
        from: 0,
        to: 0,
      })
    );
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  closeDialog() {
    this.display = false;
    this.editZone = false;
    this.deleteDialog = false;
  }

  addPostalCode() {
    const postalCodes = this.formData.get('postalCode') as FormArray;
    postalCodes.push(this.createPostalCodeGroup());
  }

  removePostalCode(index: number) {
    const postalCodes = this.formData.get('postalCode') as FormArray;
    postalCodes.removeAt(index);
  }

  handleDelete(rowData: ManageZoneData, postalCode: ZoneValue): void {
    this.rowData = rowData;
    this.zoneValue = postalCode;
    this.deleteDialog = true;
  }

  async onDelete(): Promise<void> {
    if (this.rowData.zoneValues.length === 1) {
      this.onDeleteZone();
    } else {
      this.onDeleteZoneValue();
    }
  }

  async onDeleteZoneValue(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const updatedZones = this.rowData.zoneValues
        .filter((zoneValue) => zoneValue.id !== this.zoneValue.id)
        .map((zone) => ({
          ...zone,
          postCodes: JSON.stringify(zone.postCodes),
        }));

      const response = await this.dataService.amendZoneData({
        ...this.rowData,
        zoneValues: updatedZones,
      });

      if (response) {
        this.deleteDialog = false;
        this.onSaveLoad = false;
        this.ManageZoneList = await this.zoneData();
        this.handleSearch(this.searchText);
        this.successToast('Postal Code Deleted Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onDeleteZone(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const res = await this.dataService.deleteZoneData(this.rowData.id);
      if (res) {
        this.onSaveLoad = false;
        this.ManageZoneList = await this.zoneData();
        this.handleSearch(this.searchText);
        this.deleteDialog = false;
        this.successToast('Zone Deleted Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onAmend(): Promise<void> {
    const { date, postalCode } = this.formData.value;
    const dateFrom = moment(date).format('YYYY-MM-DD');

    const indexToUpdate = this.rowData.zoneValues.findIndex(
      (zoneValue: any) => zoneValue.dateFrom === dateFrom
    );

    if (indexToUpdate !== -1) {
      await this.updateZoneValue(indexToUpdate, postalCode);
    } else {
      await this.addNewZoneValue(dateFrom, postalCode);
    }
  }

  private async updateZoneValue(
    index: number,
    postalCode: FormatZoneValue
  ): Promise<void> {
    this.onSaveLoad = true;
    try {
      const updatedZoneValues = this.rowData.zoneValues.map(
        (zoneValue: any) => ({
          ...zoneValue,
          postCodes: JSON.stringify(zoneValue.postCodes),
        })
      );
      updatedZoneValues[index].postCodes = JSON.stringify(postalCode);

      const response = await this.dataService.amendZoneData({
        ...this.rowData,
        zoneValues: updatedZoneValues,
      });

      if (response) {
        this.display = false;
        this.onSaveLoad = false;
        this.ManageZoneList = await this.zoneData();
        this.handleSearch(this.searchText);
        this.successToast('Postal Code Updated Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  private async addNewZoneValue(
    date: string,
    postalCode: FormatZoneValue
  ): Promise<void> {
    this.onSaveLoad = true;
    try {
      const newZoneValue = {
        dateFrom: date,
        postCodes: JSON.stringify(postalCode),
      };

      const updatedZoneValues = this.rowData.zoneValues.map(
        (zoneValue: any) => ({
          ...zoneValue,
          postCodes: JSON.stringify(zoneValue.postCodes),
        })
      );

      updatedZoneValues.push(newZoneValue);

      const response = await this.dataService.amendZoneData({
        ...this.rowData,
        zoneValues: updatedZoneValues,
      });

      if (response) {
        this.display = false;
        this.onSaveLoad = false;
        this.ManageZoneList = await this.zoneData();
        this.handleSearch(this.searchText);
        this.successToast('Postal Code Added Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }
}
