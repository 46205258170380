import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class CatalogueService {
  constructor(private api: ApiService) {}

  async fetchCatalogueData(pageNo: number, pageSize: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/catalogue?pageNumber=${pageNo}&pageSize=${pageSize}`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchCatalogueCount(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get('api/catalogue/count'));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchCatalogueData(
    pageNo: number,
    pageSize: number,
    val: string
  ): Promise<any> {
    try {
      const payload = {
        searchText: val,
      };
      const data = await firstValueFrom(
        this.api.post(
          `api/catalogue/search?pageNumber=${pageNo}&pageSize=${pageSize}`,
          payload
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
