import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ABN_URL } from '../../constants';
import { ABNDetailsType } from '../types';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient) {}

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(`${environment.baseApiUrl}/${url}`);
  }

  getBlob(url: string): Observable<Blob> {
    return this.http.get(`${environment.baseApiUrl}/${url}`, {
      responseType: 'blob',
    });
  }

  post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(`${environment.baseApiUrl}/${url}`, data);
  }

  patch<T>(url: string, data: any): Observable<T> {
    return this.http.patch<T>(`${environment.baseApiUrl}/${url}`, data);
  }

  put<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(`${environment.baseApiUrl}/${url}`, data);
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(`${environment.baseApiUrl}/${url}`);
  }

  getAbnData(abn: number): Observable<ABNDetailsType> {
    if (!abn) {
      throw new Error('Provide an ABN number!');
    }

    const url = `${ABN_URL}&abn=${abn}`;
    return this.http.jsonp(url, 'JSONP_CALLBACK');
  }
}
