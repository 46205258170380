import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { DropDownType, TableCols } from '../../types';
import { CERTIFICATE_VALUE, DASHBOARD_TABLE } from '../../../constants';
import { DASHBOARD } from '../../../mock-data';
import { ChartModule } from 'primeng/chart';
import moment from 'moment';
import { InstallerService } from '../installers/installers.services';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [SharedModule, ChartModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  data: any[] = DASHBOARD;
  cols: TableCols[] = DASHBOARD_TABLE;

  price: any;
  options: any;
  allPrice: any[] = [];
  CertificateList: DropDownType[] = CERTIFICATE_VALUE;
  installer: any;
  installerCertificateData: any;
  basicOptions: any;
  installersList: any[] = [];
  certificateData: any[] = [];

  constructor(
    private service: InstallerService,
    private dashboardService: DashboardService
  ) {}

  async ngOnInit() {
    this.initialBarChartComponent();
    this.installersList = await this.getInstallerData(0, 100);
    await this.getCertificateData(this.installer);
    this.initialBarChartComponent();
  }

  async getInstallerData(pageNo: number, pageSize: number): Promise<any[]> {
    try {
      const res = await this.service.fetchInstallerData(pageNo, pageSize, '');
      if (res) this.installer = res.data[0].id;
      return res.data;
    } catch (error: any) {
      return [];
    }
  }

  async getCertificateData(id: number): Promise<any[]> {
    try {
      const res = await this.dashboardService.fetchCertificateData(id);
      if (res) this.certificateData = res;
      return res.data;
    } catch (error: any) {
      return [];
    }
  }

  private labelFormat(): string[] {
    return Array.from({ length: 3 }, (_, i) =>
      moment()
        .subtract(2 - i, 'months')
        .format('MMMM')
    );
  }

  initialBarChartComponent() {
    const documentStyle = getComputedStyle(document.documentElement);

    const chartColors = this.extractChartColors(documentStyle);
    const labels = this.labelFormat();
    const pastelColors = this.getPastelColors();
    const certificateOrder = ['STC', 'PRC', 'VEEC', 'ESC', 'LCG', 'ACCU'];

    const certificateMap = this.initializeCertificateMap(
      certificateOrder,
      labels.length
    );
    this.updateCertificateMap(certificateMap, labels);

    const outputData = this.generateOutputData(
      certificateOrder,
      certificateMap,
      documentStyle,
      pastelColors
    );

    this.installerCertificateData = this.createChartData(labels, outputData);
    this.basicOptions = this.createChartOptions(chartColors);
  }

  extractChartColors(documentStyle: CSSStyleDeclaration) {
    return {
      textColor: documentStyle.getPropertyValue('--text-color'),
      textColorSecondary: documentStyle.getPropertyValue(
        '--text-color-secondary'
      ),
      surfaceBorder: documentStyle.getPropertyValue('--surface-border'),
    };
  }

  getPastelColors(): string[] {
    return [
      '--blue-200',
      '--pink-200',
      '--green-200',
      '--yellow-200',
      '--purple-200',
      '--red-200',
    ];
  }

  initializeCertificateMap(
    certificateOrder: string[],
    monthsCount: number
  ): Map<string, number[]> {
    const certificateMap = new Map<string, number[]>();
    certificateOrder.forEach((certificate) => {
      certificateMap.set(certificate, Array(monthsCount).fill(0));
    });
    return certificateMap;
  }

  updateCertificateMap(
    certificateMap: Map<string, number[]>,
    targetMonths: string[]
  ) {
    this.certificateData.forEach(
      ({ certificateName, month, certificateValue }) => {
        const monthIndex = targetMonths.indexOf(month);
        if (monthIndex !== -1 && certificateMap.has(certificateName)) {
          const dataSets = certificateMap.get(certificateName);
          if (dataSets) {
            dataSets[monthIndex] = certificateValue;
          }
        }
      }
    );
  }

  generateOutputData(
    certificateOrder: string[],
    certificateMap: Map<string, number[]>,
    documentStyle: CSSStyleDeclaration,
    pastelColors: string[]
  ) {
    return certificateOrder.map((certificateName, index) => ({
      label: certificateName,
      backgroundColor: documentStyle.getPropertyValue(pastelColors[index]),
      borderColor: documentStyle.getPropertyValue(pastelColors[index]),
      data: certificateMap.get(certificateName),
    }));
  }

  createChartData(labels: string[], outputData: any[]) {
    return {
      labels,
      datasets: outputData,
    };
  }

  createChartOptions(chartColors: {
    textColor: string;
    textColorSecondary: string;
    surfaceBorder: string;
  }) {
    return {
      plugins: {
        legend: {
          labels: {
            color: chartColors.textColor,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: chartColors.textColorSecondary,
          },
          grid: {
            color: chartColors.surfaceBorder,
            drawBorder: false,
          },
        },
        x: {
          ticks: {
            color: chartColors.textColorSecondary,
          },
          grid: {
            color: chartColors.surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };
  }

  async onCertificateTypeChange(event: any): Promise<void> {
    await this.getCertificateData(event.value);
    this.initialBarChartComponent();
  }
}
